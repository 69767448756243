import React, { useState } from "react";
import search from "../../assets/search.svg";
import clear from "../../assets/clear.svg";
import oval from "../../assets/oval.svg";
import copy from "../../assets/search.svg";


var curr_url = window.location.href;

const Search = ({
  searchValue,
  setSearchValue,
  loading,
  handleGetSearchData,
}) => {
  const [showCopy, setShowCopy] = useState("Copy");
  const [showSearch, setShowSearch] = useState("Search");

  var str = curr_url;
  var strPos = str.indexOf("avalara-workorder") > -1;
 
  const handleButtonClick = () => {
    // You can change the searchValue based on your requirements.
    const SStrringY = document.getElementById('X87').value;
    console.log("SStrringY >> ", SStrringY)
    const stringWithoutSpaces = SStrringY.replace(/\s/g, '');
    console.log("stringWithoutSpaces >> ", stringWithoutSpaces)
    setSearchValue(stringWithoutSpaces);
  };
 
  return (
    <div className="search-wrapper">
      
      <img src={search} alt="search" />
      <input
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        type="text"
        alt="search"
        class="XXXXXX"
        id="X87"
        //2022-03-04 -- added autofocus
        autoFocus
        
         //2022-03-04 -- added autofocus

         //2022-03-04 -- added autofocus
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleGetSearchData()
            //console.log('Enter(2)')
          }
        }}
         //2022-03-04 -- added autofocus
         
      />

      {searchValue !== "" && (
        <img
          className="clear-img"
          onClick={() => setSearchValue("")}
          src={clear}
          alt="clear"
        />
      )}
      {loading && <img className="loader-img" src={oval} alt="loading" />}

      {showCopy && strPos && (
        <button
          onClick={() => {
            copyDivToClipboard();
            setShowCopy("Copied");
          }}
          className="copy-button"
        >
          {showCopy}
        </button>
      )}
      
      {showSearch && (
        <button
          onClick={() => {
            console.log("Refresh button")
            handleButtonClick();
            handleGetSearchData();
            setShowCopy("Copy");
            setShowSearch("Refresh");
          }}
          className="search-button"
        >
          {showSearch}
        </button>
      )}

      

     

    </div>
  );
};

export default Search;

function copyDivToClipboard() {
  var range = document.createRange();
  range.selectNode(document.getElementById("copyy"));
  window.getSelection().removeAllRanges(); // clear current selection
  window.getSelection().addRange(range); // to select text
  document.execCommand("copy");
  window.getSelection().removeAllRanges();// to deselect
}
 