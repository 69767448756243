import React, { useRef, useEffect } from "react";
import CustomButton from "./CustomButton";
import lottie from "lottie-web";
import successAnim from "../assets/success.json";
import { Route, useLocation } from "react-router-dom";
import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";
import Search from "./searchPage/Search";

import ComponentB from '../App';

const SuccessPage = ({errorMsg}) => {
  const animRef = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: animRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: successAnim,
    });
  }, []);

  const { search } = useLocation();
  setTimeout(function() {
    if (window && window.parent) {
      const amount = new URLSearchParams(window.location.search).get('amount')
      const UUID = Math.random().toString(36).substring(7);
      const approvedAmount = document.getElementById('approved-amount').value
      
      var approvedAmountCARD = document.getElementById('GivexCardID').innerHTML
      console.log("approvedAmountCARD >>", approvedAmountCARD)

      let VEND_URL = "" // IMPORTANT: You should change this to be your Vend domain URL.
      try{
        VEND_URL = search
        .split("&")
        .filter((item) => item.indexOf("origin") > -1)[0]
        .split("=")[1];
        VEND_URL = decodeURIComponent(VEND_URL);
      } catch (err) {}
   
      
      let gname = document.getElementById('ggname').value;
      
      var rreceipt_html_extra = '<h1>Room Charge</h1><p>Guest Name: '+ gname +'</p><br/><br/><span>Signature:_____________________</span>';

      let CurrentURL2 = window.location.href;
      let IsGivex2 = CurrentURL2.includes("givex");
      if (IsGivex2) {
        rreceipt_html_extra = "";
        var newStr = approvedAmountCARD.replace("?", "");
        var newStr = newStr.replace(";", "");

        rreceipt_html_extra = '<p>Givex Card Number: '+ newStr +'</p>';
        rreceipt_html_extra = rreceipt_html_extra + '<p>'+ errorMsg +'</p>'
        console.log("rreceipt_html_extra >> ", rreceipt_html_extra)
      };
      
      console.log("IsGivex2 -> ", IsGivex2)
      console.log("rreceipt_html_extra --> ",rreceipt_html_extra)
      
      let CurrentURL3 = document.getElementById('GivexOperationX').innerText;
      
      console.log("CurrentURL3 ->> ", CurrentURL3)

      if (CurrentURL3.includes("givex-integra-905") || CurrentURL3.includes("givex-integra-906")) {
        const data = {
          step: 'EXIT',
          receipt_html_extra: rreceipt_html_extra,
        }
        //console.log(data)
        console.log('we have message sending here', window.parent);
        window.parent.postMessage(JSON.stringify(data), VEND_URL);
      } else {
        const data = {
          step: 'ACCEPT',
          receipt_html_extra: rreceipt_html_extra,
          name: 'payment',
          success: true,
          print: false,
          external_transaction_id: UUID,
          reference_id: UUID,
          amount,
          approved_amount: approvedAmount,
          //receipt_html_extra: 'Guest name: ' + gname,
        }
        //console.log(data)
        console.log('we have message sending here', window.parent);
        window.parent.postMessage(JSON.stringify(data), VEND_URL);
    }
    }
  },1000);
 
  return (
    
    <>
      <div className="page">
        <div ref={animRef} className="lottie-anim" />
        <p>Success</p>
        <p className="grey-text">{errorMsg}</p>
      </div>
      
      {/*
      <div className="footer">
        <CustomButton text="Go back" />
      </div>
      */}

    </>
  );
};

export default SuccessPage;
