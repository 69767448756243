import { useState, useEffect } from "react";

import svgX from "./assets/x.svg";

import SearchPage from "./components/SearchPage";
import ProceessingPage from "./components/ProceessingPage";
import SuccessPage from "./components/SuccessPage";
import FailPage from "./components/FailPage";

import { Route, useLocation } from "react-router-dom";

import * as axiosFns from "./axios";
import { waitFor } from "@testing-library/react";
 

const App = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [populateValue, setPopulateValue] = useState("");
    const [db, setDb] = useState([]);
    const [loading, setLoading] = useState(false);
    const [GivexOperationName, setGivexOperationName] = useState("");

    const [errorMsg, setErrorMsg] = useState(
        "Unknown error. Please contact your PMS provider."
    );

    const closeTab = () => {
        parent.postMessage(JSON.stringify({
            step: 'EXIT',
            name: 'payment',
            print: false,
        }), VEND_URL)
    };

    const RequestSaleData = () => {
        /*
        parent.postMessage(JSON.stringify({
            step: 'EXIT',
            name: 'payment',
            print: false,
        }), VEND_URL)
        */
    };

   

    const { search } = useLocation();
    let accountId = "";
    let shopId = "";
    let saleId = "";
    let workorderID = "";
    let extra1 = "";
    let extra2 = "";
    let extra3 = "";
    let extra4 = "";
    let extra5 = "";
    let extra6 = "";
    let extra7 = "";
    let registerID;
    let roomId = "";
    let refundId = "0";
    let operationName = "";
    let accountDataId = "";
    let guestName = "";
    let reservationId = "";
    let isPopulate = "";
    let customerId = "";
    let returnURL = "";


    let MySalqeData = "xx";

    let CurrentURL = window.location.href;

    //console.log(CurrentURL);
    //console.log(CurrentURL.includes("givex"));
    let IsGivex = CurrentURL.includes("givex");



    const parent = window.parent
    let VEND_URL = "" // IMPORTANT: You should change this to be your Vend domain URL.
    try {
        VEND_URL = search
            .split("&")
            .filter((item) => item.indexOf("origin") > -1)[0]
            .split("=")[1];
        VEND_URL = decodeURIComponent(VEND_URL);
    } catch (err) {}

    const amount = new URLSearchParams(window.location.search).get('amount')
    const UUID = Math.random().toString(36).substring(7);
    const mySale = {}

   
    window.addEventListener(
        'load',
        function(event) {
            const data = {
                name: 'payment',
                success: true,
                step: 'DATA',
            }
            try {
                parent.postMessage(JSON.stringify(data), VEND_URL)
            } catch (err) {}
        },
        false
    );

    // Listen for postMessages from Vend
    window.addEventListener('message',
        function(event) {
            try {
                const data = JSON.parse(event.data)
                //onsole.log("BBBBBBBBB -> ",data.step)
                document.getElementById('approved-amount').value = amount;
                document.getElementById('json-data').innerText = JSON.stringify(data, undefined, 2)
                switch (
                    data.step // The step of the payment according to Vend
                ) {
                    case 'DATA': // DATA: Vend is sending you more data about the sale
                        //console.log('Received data about this sale', data)
                        MySalqeData = document.getElementById('json-data').innerText;
                        break
                }
            } catch (err) {}
        },
        false
    );
    

    //MySalqeData = document.getElementById('json-data').innerText;

    try {
        returnURL = search
            .split("&")
            .filter((item) => item.indexOf("returnURL") > -1)[0]
            .split("=")[1];
    } catch (err) {}
    returnURL = decodeURIComponent(returnURL);

    if (!!search) {
        try {
            customerId = search
                .split("&")
                .filter((item) => item.indexOf("customerID") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            accountId = search
                .split("&")
                .filter((item) => item.indexOf("accountID") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            saleId = search
                .split("&")
                .filter((item) => item.indexOf("saleID") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            workorderID = search
                .split("&")
                .filter((item) => item.indexOf("workorderID") > -1)[0]
                .split("=")[1];
        } catch (err) {}
        //console.log(workorderID);



        try {
            extra1 = search
                .split("&")
                .filter((item) => item.indexOf("amount") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            extra2 = search
                .split("&")
                .filter((item) => item.indexOf("register_id") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            extra3 = search
                .split("&")
                .filter((item) => item.indexOf("currency") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            extra4 = search
                .split("&")
                .filter((item) => item.indexOf("retailer_payment_type_id") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            extra5 = search
                .split("&")
                .filter((item) => item.indexOf("origin") > -1)[0]
                .split("=")[1];
        } catch (err) {}


        try {
            extra6 = search
                .split("&")
                .filter((item) => item.indexOf("reference_id") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            shopId = search
                .split("&")
                .filter((item) => item.indexOf("shopID") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            registerID = search
                .split("&")
                .filter((item) => item.indexOf("registerID") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            refundId = search
                .split("&")
                .filter((item) => item.indexOf("refund") > -1)[0]
                .split("=")[1];
        } catch (err) {}


        try {
            operationName = search
                .split("&")
                .filter((item) => item.indexOf("operation") > -1)[0]
                .split("=")[1];
        } catch (err) {}
    }


    useEffect(() => {
        if (operationName.indexOf("populate") > -1)
            axiosFns.getPopulate(
                setPopulateValue,
                accountId,
                customerId,
                operationName
            );
    }, []);


    useEffect(() => {
        if (!populateValue) return;
        axiosFns.getSearchData(
            accountId,
            shopId,
            registerID,
            operationName,
            workorderID,
            populateValue,
            setDb,
            setLoading
        );
        setSearchValue(populateValue);
    }, [populateValue]);

    //console.log(accountId);
    // 

    if (IsGivex) {
        operationName = GivexOperationName;

        operationName ? (
            console.log("")
        ) : (
            //operationName = "givex-integra-searchonly"
            operationName = "givex-integra-902"
        )

        //console.log(CurrentURL)
    };

    console.log("operationName2 >> ", operationName);

    const handleGetSearchData = () =>
        axiosFns.getSearchData(
            accountId,
            shopId,
            registerID,
            operationName,
            workorderID,
            searchValue,
            setDb,
            setLoading
        );

    try {
        //extra7 = document.getElementById('json-data').innerText;
        extra7 = document.getElementById('json-data').innerText;
    } catch (err) {}

    //console.log(extra7);

    
    
    const handleGetRoomCharges = (
        roomIdInput,
        accountDataIdX,
        guestNameX,
        reservationIdX
    ) => {
        roomId = roomIdInput;
        accountDataId = accountDataIdX;
        guestName = guestNameX;
        document.getElementById('ggname').value = guestName;
        document.getElementById('GivexCardID').innerText = reservationIdX;
        reservationId = reservationIdX;
        axiosFns.getRoomCharges(
            accountId, -9999999, //saleId,
            roomId,
            accountDataIdX,
            guestNameX,
            reservationIdX,
            refundId,
            operationName,
            extra1,
            extra2,
            extra3,
            extra4,
            extra5,
            extra6,
            extra7,
            setCurrentPage,
            setErrorMsg
        );
    };
    const handleGetRoomChargesRetry = () => {
        axiosFns.getRoomCharges(
            accountId, -9999999, //saleId,
            roomId,
            accountDataId,
            guestName,
            reservationId,
            refundId,
            operationName,
            setCurrentPage,
            setErrorMsg
        );
    };


    const handleClick = (e) => {
        e.preventDefault();
        //console.log('The link was clicked.');
        //console.log(e.target.id);
        
        let OperationValue = "";
        try {
            OperationValue = search
                .split("&")
                .filter((item) => item.indexOf("operation") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        
        setGivexOperationName(e.target.id);
        document.querySelector("#root .XXXXXX").focus();
        let AppTitle = "";

        {
            IsGivex ? (
                AppTitle = "X-Series Givex (v1.3)"
            ) : (
                AppTitle = "X-Series Room Charges (v1.4)"
            )
        }
        document.getElementsByClassName("TitleX")[0].innerHTML = AppTitle + "  ****   " + e.target.innerText;
        console.log("givex-integra >> ",e.target.id);
        console.log("CurrentURL >> ", CurrentURL.replace(OperationValue, e.target.id));
        document.getElementById('GivexOperationX').innerText = e.target.id;

    }


    const addTips = () => {
        window.postMessage(JSON.stringify({
            method: 'add-product',
            params: {
              //product_id: '0adaafb3-6583-11e5-fb60-d5c48562bd5b',
              sku: 'XTIP',   // optional - can be used instead of product_id
              quantity: 1,   // optional
              unit_price: 5, // optional
              note: 'via API',   // optional
              confirmed: true    // optional
            }
          }), window.location.origin);
    };

    const test1 = () => {
        const data = {
            step: 'SETUP',
            setup: {
                enable_close:true      
            }
          }
          //console.log(data)
          console.log('we have message sending here', window.parent);
          window.parent.postMessage(JSON.stringify(data), VEND_URL);
    };

    const test2 = () => {
        const data = {
            actions: "UPDATE_CACHES"
          }
          //console.log(data)
          console.log('we have message sending here', window.parent);
          window.parent.postMessage(data, VEND_URL);
    };

    const ppay = () => {
        window.postMessage(JSON.stringify({
            method: 'add-product',
            params: {
              product_id: "13566b87-736d-4783-9ff5-b74bf8a41a44",
              //sku: 'some_sku',   // optional - can be used instead of product_id
              quantity: 1,   // optional
              unit_price: 235, // optional
              note: 'via API',   // optional
              confirmed: true    // optional
            }
          }), window.location.origin);
    };

    return ( 
        <Route path = "/">
        <div className = "app">
        <div className = "main-app">
        <div className = "header">

        {
            IsGivex ? ( <p class = "TitleX" > X-Series Givex (v1.3) **** Redeem</p>
            ) : ( <p class = "TitleX" > X - Series Room Charges(v1.4) </p>
            )
        }


        <div onClick={closeTab} className="x-wrapper">
            <img src={svgX} alt="x" />
        </div>
        </div>

        <div class = "container center" >
        <div id = "statusMessage" > 
        </div> 
        <div id = "outcomes" >
        <div class = "center-text" >
        <div class = "GivexClass" >



        {
            IsGivex ? ( 
                <button id = "givex-integra-902" onClick = { handleClick } className = "GivexButton1" >Redeem </button>
            ) : ( <> </>
            )
        }

        
        {
            IsGivex ? ( <button id = "givex-integra-905"onClick = { handleClick }className = "GivexButton3" >Add balance </button>
            ) : ( <> </>)
        }
        
        {
            IsGivex ? ( <button id = "givex-integra-906"onClick = { handleClick }className = "GivexButton4" >Card activation </button>
            ) : ( <> </>)
        
        } 

        </div> 
        </div> 
        </div> 
        </div>



        <div id = "payment-window">
        <div id = "payment-process">
        </div>



        </div> 
        <div className = "main-content">

        {
            IsGivex ? ( <> </>
            ) : ( <> </>
            )
        }


        {
            currentPage === 0 && ( <
                SearchPage db = { db }
                loading = { loading }
                handleGetSearchData = { handleGetSearchData }
                handleGetRoomCharges = { handleGetRoomCharges }
                setCurrentPage = { setCurrentPage }
                searchValue = { searchValue }
                setSearchValue = { setSearchValue }
                />
            )
        }

        { currentPage === 1 && < ProceessingPage / > } { currentPage === 2 && < SuccessPage errorMsg = { errorMsg } / > } {
            currentPage === 3 && ( <
                FailPage handleGetRoomChargesRetry = { handleGetRoomChargesRetry }
                setCurrentPage = { setCurrentPage }
                errorMsg = { errorMsg }
                />
            )
        }



        </div> 
        </div> 
        </div> 
        <button id = "test1" onClick={test1}>test1</button>
        <button id = "test2" onClick={test2}>test2</button>
        <div id="ggname"></div>
        <button hidden id = "request-data" onClick={RequestSaleData}> Request Sale Data </button> 
        <button id = "add_tips" onClick={addTips}> Add Tips </button> 
        <button id = "accept" onClick={ppay}> Accept </button> 
        <button hidden id = "decline" > Decline </button> 
        <button hidden id = "cancel" onClick={closeTab}> Cancel </button> 
        <label> Approved amount: <input id = "approved-amount" / > </label> 
        <div id="GivexCardID"></div>
        <h2 > Data received: </h2> 
        <pre id = "json-data"
        
        
        class = "prettyprint lang-json" > {} 
        </pre> 
        <pre id = "GivexOperationX">GIVEX</pre>
        </Route>

    );


};

export default App;