import React, { useState } from "react";

import Search from "./searchPage/Search";
import Table from "./searchPage/Table";

    const SearchPage = ({
      db,
      loading,
      handleGetSearchData,
      handleGetRoomCharges,
      setCurrentPage,
      searchValue,
      setSearchValue,
    }) => {
    //console.log(db, "search page");

    //console.log("json1");
    //console.log(document.getElementById('json-data').innerText);
    //console.log("json2");
   
    var pageURL = decodeURIComponent(window.location.search.substring(1)),
      params = pageURL.split('&'),
      paramName,
      parameters = {}
  
    params.forEach(function (param) {
      paramName = param.split('=')
  
      //console.log(paramName)
  
      switch (paramName[0]) {
        case 'amount':
          parameters.amount = paramName[1]
          break
        case 'origin':
          parameters.origin = paramName[1]
          break
        case 'register_id':
          parameters.register_id = paramName[1]
          break
      }
    })
  
    //console.log(parameters)

    let CurrentURL = window.location.href;
    let IsGivex = CurrentURL.includes("givex");

    try {
    return (
      <>
        {IsGivex ? (
          <Search
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            loading={loading}
            handleGetSearchData={handleGetSearchData}
          />
        ) : (
          <Search
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            loading={loading}
            handleGetSearchData={handleGetSearchData}
          />
        )}
        <Table
          handleGetRoomCharges={handleGetRoomCharges}
          setCurrentPage={setCurrentPage}

          
          db={db.filter(
            (item) =>            
              item.account_id
                .toLowerCase()
                .indexOf(searchValue?.toLocaleLowerCase()) > -1 ||
                //.indexOf(searchValue.toLocaleLowerCase()) > -1 ||
              item.guest_name
                .toLowerCase()
                .indexOf(searchValue?.toLocaleLowerCase()) > -1 ||
              item.reservation_id
                .toLowerCase()
                .indexOf(searchValue?.toLocaleLowerCase()) > -1
          )}
        />
      </>
    ) } catch (err) {};
  };


export default SearchPage;
